import React, { useState, useEffect } from 'react';

function StoreItemSized(props) {
    const [selectedSize, setSelectedSize] = useState("");
    const [isOutOfStock, setIsOutOfStock] = useState(false);
    const [availableSizes, setAvailableSizes] = useState({
        S: true,
        M: true,
        L: true,
        XL: true
    });

    const BACKEND_ORIGIN = process.env.REACT_APP_BACKEND_ORIGIN || 'localhost:3030';
    const PROTOCOL = window.location.protocol;

    useEffect(() => {
        async function checkInventory() {
            try {
                const sizeAvailability = { S: true, M: true, L: true, XL: true };

                for (const size of ['S', 'M', 'L', 'XL']) {
                    const response = await fetch(`${PROTOCOL}//${BACKEND_ORIGIN}/inventory/${props.name}/${size}`);
                    if (response.ok) {
                        const data = await response.json();
                        sizeAvailability[size] = data.quantity > 0;
                    } else {
                        console.error(`Failed to fetch inventory for size ${size}`);
                    }
                }

                setAvailableSizes(sizeAvailability);

                // Check if all sizes are out of stock
                const allOutOfStock = Object.values(sizeAvailability).every(isAvailable => !isAvailable);
                setIsOutOfStock(allOutOfStock);
            } catch (error) {
                console.error("Error checking inventory:", error);
            }
        }

        checkInventory();
    }, [props.name]);

    const handleAddToCart = () => {
        // Call the parent's quantity change function
        if (!isOutOfStock && (selectedSize !== "")) {
            props.onQuantityChange(selectedSize);
        }
    };

    const handleSizeChange = (event) => {
        setSelectedSize(event.target.value);
    };

    return (
        <div className="storeItem">
            <img src={props.img} alt={props.name} />
            <h3>{props.name}</h3>
            <p>${props.price}</p>
            <div className="quantityControl">
                <button onClick={handleAddToCart} disabled={isOutOfStock || (props.size && !selectedSize)}>
                    Add to Cart
                </button>
            </div>
            <div className="sizeSelector">
                <select disabled={isOutOfStock} value={selectedSize} onChange={handleSizeChange}>
                    <option value="" disabled style={{ color: 'gray', fontStyle: 'italic' }}>
                        Select size
                    </option>
                    {['S', 'M', 'L', 'XL'].map(size => (
                        <option key={size} value={size} disabled={!availableSizes[size]}>
                            {size} {availableSizes[size] ? '' : '(Out of Stock)'}
                        </option>
                    ))}
                </select>
            </div>
            <div>{isOutOfStock && <p style={{ color: 'red' }}>Out of Stock</p>}</div>
            
        </div>
    );
}

export default StoreItemSized;