import React from 'react';

function Cart({ quantities, products, totalPrice, onQuantityDecrement }) {

    const itemNameMap = {
        sketchbook: "Sketch Book",
        shirt: "Shirt",
        bag: "Tote Bag"
    };

    console.log(totalPrice);
    
    return (
        <div className="cart">
            <h2>Cart</h2>
            <ul>
                {Object.entries(quantities).map(([itemName, quantity]) => {
                    if (typeof quantity === 'object') {
                        return Object.entries(quantity).map(([size, sizeQuantity]) => {
                            if (sizeQuantity > 0) {
                                const sizeKey = `${itemName}${size}`;
                                return (
                                    <li key={sizeKey}>
                                        {`${itemNameMap[itemName]} (${size})`} {sizeQuantity} x ${products[sizeKey].toFixed(2)}
                                        <button 
                                            onClick={() => onQuantityDecrement(itemName, size)}
                                            disabled={sizeQuantity === 0}
                                        >
                                            -
                                        </button>
                                    </li>
                                );
                            }
                            return null;
                        });
                    }
                    // For non-shirt items (e.g., sketchbook, bag)
                    if (quantity > 0) {
                        return (
                            <li key={itemName}>
                                {itemNameMap[itemName]}: {quantity} x ${products[itemName].toFixed(2)}
                                <button 
                                    onClick={() => onQuantityDecrement(itemName)}
                                    disabled={quantity === 0}
                                >
                                    -
                                </button>
                            </li>
                        );
                    }
                    return null;
                })}
            </ul>
            <p><strong>Total: ${totalPrice.toFixed(2)}</strong></p>
        </div>
    );
}

export default Cart;