import React, { useState } from 'react';
import NavBar from '../NavBar.js';
import '../App.css';
import envelope from '../res/envelope.png';

function Photos() {
    const [expandedImage, setExpandedImage] = useState(null);
    const [dispPhotos, setDispPhotos] = useState([]);

    const images = [...Array(6)].map((_, index) => envelope); // Example images array
    console.log(images)
    const imageLabels = [
        'Joshua Tree 09-2024',
        'San Diego 02-2024',
        'Biosphere / AZ 03-2024',
        'Niagara Falls 04-2024',
        'New England 08-2024',
        'Flagstaff / SD 05-2024'
    ]
    let imageIndex = 0;
    let imageMax = 0;

    const photoGroups = {
        1: require.context('../res/photos/photoGroup1', false, /\.jpg$/),
        2: require.context('../res/photos/photoGroup2', false, /\.jpg$/),
        3: require.context('../res/photos/photoGroup3', false, /\.jpg$/),
        4: require.context('../res/photos/photoGroup4', false, /\.jpg$/),
        5: require.context('../res/photos/photoGroup5', false, /\.jpg$/),
        6: require.context('../res/photos/photoGroup6', false, /\.jpg$/),
        // Add more photo groups as needed
    };
    
    // Helper function to retrieve images from a specific photo group
    const getImages = (groupIndex) => {
        const context = photoGroups[groupIndex];
        if (!context) return []; // Return an empty array if the context isn't defined
    
        return context.keys().map(context); // Load all images from the selected group
    };
    

    const handleImageClick = (index) => {
        // Toggle the expanded image
        console.log(index, expandedImage);
        const fetchedImages = getImages(index + 1);
        shuffleArray(fetchedImages);
        setDispPhotos(fetchedImages);
        imageIndex = 1;
        imageMax = dispPhotos.length + 1;
        if (expandedImage === index) {
            document.getElementsByClassName('expanded')[0].src=envelope;
            console.log(fetchedImages[0]);
        }
        setExpandedImage(expandedImage === index ? null : index);
    };

    const handlePrevClick = () => {
        imageIndex = (imageIndex - 1);
        if (imageIndex < 0) {
            imageIndex = dispPhotos.length - 1;
        }
        document.getElementsByClassName('expanded')[0].src=dispPhotos[imageIndex];
        console.log(imageIndex);
            
    };

    const handleNextClick = () => {
        imageIndex = (imageIndex + 1) % dispPhotos.length;
        document.getElementsByClassName('expanded')[0].src=dispPhotos[imageIndex];
        console.log(imageIndex);
    };

    return (
        <div>
            <NavBar />
            <div id="photosBody">
                <div className="grid-container">
                    {images.map((src, index) => (
                        <div className="image-container" key={index} id={index.toString()}>
                            <img
                                src={src}
                                alt={`Image ${index + 1}`}
                                onClick={() => {
                                    if (expandedImage === index || expandedImage === null) {
                                        handleImageClick(index);
                                    }
                                }}
                                className={expandedImage === index ? 'expanded' : expandedImage !== null ? 'hidden' : 'gridImage'}
                            />
                            {expandedImage === null && (
                                <div className="image-label">{imageLabels[index]}</div>
                            )}
                        </div>
                    ))}
                </div>
                {/* Render buttons only when an image is expanded */} 
                {expandedImage !== null && ( // need to fix the button layout, it's fucked up right now.
                    <div className="button-container">
                        <button className="photoFlipBtn" onClick={handlePrevClick}>Prev</button>
                        <button className="photoFlipBtn" onClick={handleNextClick}>Next</button>
                    </div>
                )}
            </div>
        </div>
    );
}


function shuffleArray(array) {
    for (var i = array.length - 1; i >= 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
}

export default Photos;
