import React from 'react';
import { createRoot } from 'react-dom/client';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import './index.css';
import Sketchbook from './art/sketchbook';
import Paintings from './art/paintings';
import Photos from './art/photos';
import About from './about/about';
import Gallery from './gallery/gallery';
import Store from './store/store';
import Checkout from './store/checkout';
import StoreReturn from './store/storeReturn';
import * as serviceWorker from './serviceWorker';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <BrowserRouter >
    <Routes>
      <Route exact path="/" element={<Sketchbook/>} />
      <Route exact path="/drawings" element={<Sketchbook/>} />
      <Route exact path="/paintings" element={<Paintings/>} />
      <Route exact path="/about" element={<About/>} />
      <Route exact path="/gallery" element={<Gallery/>} />
      <Route exact path= "/store" element={<Store/>} />
      <Route exact path="/checkout" element={<Checkout/>} />
      <Route exact path="/return" element={<StoreReturn/>} />
      <Route exact path="/photos" element={<Photos/>} />
    </Routes>
  </BrowserRouter >
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
