import React, { useState, useEffect } from 'react';

function StoreItem(props) {
    const [isOutOfStock, setIsOutOfStock] = useState(false); // Tracks stock status
    
    const BACKEND_ORIGIN = process.env.REACT_APP_BACKEND_ORIGIN || 'localhost:3030';
    const PROTOCOL = window.location.protocol;

    // Function to check inventory for this item
    useEffect(() => {
        async function checkInventory() {
            try {
                const response = await fetch(`${PROTOCOL}//${BACKEND_ORIGIN}/inventory/${props.name}`);
                if (response.ok) {
                    const data = await response.json();
                    setIsOutOfStock(data.quantity === 0); // Disable if quantity is 0
                } else {
                    console.error("Failed to fetch inventory");
                }
            } catch (error) {
                console.error("Error checking inventory:", error);
            }
        }

        checkInventory();
    }, [props.name]);

    const handleAddToCart = () => {
        // Call the parent's quantity change function
        if (!isOutOfStock) {
            props.onQuantityChange(props.quantity + 1);
        }
    };

    return (
        <div className="storeItem">
            <img src={props.img} alt={props.name} />
            <h3>{props.name}</h3>
            <p>${props.price}</p>
            <div className="quantityControl">
                <button onClick={handleAddToCart} disabled={isOutOfStock}>
                    Add to Cart
                </button>
            </div>
            <div>{isOutOfStock && <p style={{ color: 'red' }}>Out of Stock</p>}</div>
            
        </div>
    );
}

export default StoreItem;

