import React, { useCallback } from "react";
import {loadStripe} from '@stripe/stripe-js';
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout
} from '@stripe/react-stripe-js';
import { useLocation } from 'react-router-dom';
import '../App.css';
import NavBar from '../NavBar.js';


const BACKEND_ORIGIN = process.env.REACT_APP_BACKEND_ORIGIN || 'localhost:3030';
const PROTOCOL = window.location.protocol;
const stripePromise = loadStripe("pk_test_51QDTqN2KaUsZb1Og4Pv1RIWwM8usaW5YDplB5NlsIl075Kp5ZMlvhiwGUniIfT6w7wgY1bIz4T10W2sKTEIPbtpO00ZJMHw7a0"); // not sure if this should be publishable key or secret key...

function Checkout() {
    // need to add cart metadata... 
    const location = useLocation();
    const { lineItems } = location.state || { lineItems: [] };
    const fetchClientSecret = useCallback(() => {
        // Create a Checkout Session
        return fetch(`${PROTOCOL}//${BACKEND_ORIGIN}/create-checkout-session`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ lineItems }) 
        })
        .then((res) => res.json())
        .then((data) => data.clientSecret);
    }, []);

    const options = {fetchClientSecret};

    return (
        <div id="checkout">
            <NavBar/>
            <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={options}
            >
                <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
            </div>
    )
}

export default Checkout;
