import NavBar from '../NavBar.js';
import '../App.css';
import React, { useState, useEffect } from "react";
import { Navigate } from 'react-router-dom';

const BACKEND_ORIGIN = process.env.REACT_APP_BACKEND_ORIGIN || 'localhost:3030';
const PROTOCOL = window.location.protocol;

function StoreReturn () {
    const [status, setStatus] = useState(null);
    const [customerEmail, setCustomerEmail] = useState('');

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sessionId = urlParams.get('session_id');
	console.log(`${PROTOCOL}//${BACKEND_ORIGIN}/session-status?session_id=${sessionId}`);
        fetch(`${PROTOCOL}//${BACKEND_ORIGIN}/session-status?session_id=${sessionId}`)
        .then((res) => {
            if (!res.ok) {
                throw new Error("Network response was not ok " + res.statusText);
            }
            console.log(res);
            return res.json();
        })
        .then((data) => {
            console.log("Data received from server:", data);
            setStatus(data.status);
            setCustomerEmail(data.customer_email);
        });
    }, []);
    if (status === 'open') {
        return (
        <Navigate to="/checkout" />
        )
    }

    if (status === 'complete') {
        return (
            <div>
                <NavBar/>
                <section id="success">
                    <p>
                    We appreciate your business! A confirmation email will be sent to {customerEmail}.

                    If you have any questions, please email <a href="mailto:orders@example.com">orders@example.com</a>.
                    </p>
                </section>
            </div>
        
        )
    }
};

export default StoreReturn;
